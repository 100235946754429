<template>
  <!-- 代理商列表页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 代理商姓名 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="名称/账号">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="请输入" />
                  </a-form-item>
                </a-col>

                <!-- 按钮 -->
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="$refs.addForm.add()">新增</a-button>
                  <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), initTableData())">重置</a-button>
                  <a-button type="primary" style="margin-left: 8px" @click="initTableData">搜索</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <!-- 视频列表状态插槽 -->
            <span slot="delFlag" slot-scope="text, record" @click="clickSwitch(record)">
              <a-switch
                checked-children="启用"
                un-checked-children="禁用"
                v-model="record.checked"
                @change="changeSwitch"
              />
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.detailForm.detail(record)">详情</a>
              <a-divider type="vertical" />
              <a @click="$refs.editForm.init(record)">编辑</a>
              <!--              <a-divider type="vertical" />-->
              <!--              <a-popconfirm placement="topRight" title="确认删除当前供应商吗？" @confirm="() => sysItemDelete(record)">-->
              <!--                <a>删除</a>-->
              <!--              </a-popconfirm>-->
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            ></a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <add-form @ok="initTableData" ref="addForm"></add-form>
    <edit-form @ok="getList" ref="editForm"></edit-form>
    <detail-form ref="detailForm"></detail-form>
  </div>
</template>

<script>
import detailForm from './detailForm.vue'
import addForm from './addForm'
import editForm from './editForm'
import { storeDelete, storePageList } from '@/api/modular/mallLiving/enterprises'
const columns = [
  {
    title: '序号',
    width: '100px',
    align: 'center',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '供应商名称',
    width: '100px',
    dataIndex: 'nickname',
    align: 'center',
  },
  {
    title: '状态',
    dataIndex: 'delFlag',
    width: '100px',
    key: 'delFlag',
    align: 'center',
    scopedSlots: { customRender: 'delFlag' },
  },
  {
    title: '账号',
    align: 'center',
    width: '150px',
    dataIndex: 'mobile',
  },
  {
    title: '联系人',
    width: '130px',
    dataIndex: 'linkName',
    align: 'center',
  },
  {
    title: '联系方式',
    width: '150px',
    dataIndex: 'linkMobile',
    align: 'center',
  },
  {
    title: '收货地址',
    width: '150px',
    dataIndex: 'address',
    align: 'center',
  },

  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: '150px',
    key: 'createTime',
    align: 'center',
  },
  {
    title: '操作',
    width: '130px',
    align: 'center',
    fixed: 'right',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      recordItem: null,
      loading: false,
      queryParam: {},
      videoTypeList: [], //视频分类数据
      provinceList: [], //省份列表
      cityList: [], //市区列表
    }
  },
  components: {
    addForm,
    editForm,
    detailForm,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    focusProv() {
      this.findProv()
    },
    //查询省份信息
    findProv() {
      this.axios.post('/sysDistrict/allProvince').then((res) => {
        if (res.success) {
          this.provinceList = res.data
        }
      })
    },
    changeProv() {
      this.cityList = ''
      delete this.queryParam.cityId
      console.log('22', this.cityList, '33', this.queryParam.cityId)
      this.axios.post('/sysDistrict/findCity', null, { params: { id: this.queryParam.provinceId } }).then((res) => {
        if (res.success) {
          this.cityList = res.data
        }
      })
    },
    getList() {
      this.loading = true
      var params = {
        page: this.page,
        queryParam: this.queryParam,
      }
      storePageList(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 删除
    sysItemDelete(record) {
      this.recordItem = record
      this.disableCommodityCate(2) //1:启用,2:删除,3:禁用
    },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await storeDelete(params)
      var text = delFlag == 1 ? '启用' : delFlag == 2 ? '删除' : '禁用'
      if (data.code == 200) {
        this.$message.success('供应商已' + text)
        this.getList()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}
.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}
.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}
.right-content {
  box-sizing: border-box;
  padding: 10px;
}
.icon {
  font-size: 20px;
  margin: 0 5px;
}
img {
  width: 20px;
  height: 20px;
}
</style>
